import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Key } from '@opengov/component-library/capital';
import { FigmaEmbed, PatternExample, UsageBlock } from '../../../components';
import LayoutComponents from '../../../components/capital/Layouts/LayoutComponents';
export const _frontmatter = {
  "title": "Key",
  "componentId": "key",
  "description": "Keys display the number or status of a step within a flow.",
  "activeTab": "components"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = LayoutComponents;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <p><strong parentName="p">{`Documentation in progress---------------------`}</strong></p>
    <h2>{`Variants`}</h2>
    <PatternExample example={<Key text="1" mdxType="Key" />} title="Neutral" mdxType="PatternExample" />
    <PatternExample example={<Key variant="active" text="1" mdxType="Key" />} title="Active" mdxType="PatternExample" />
    <PatternExample example={<Key variant="inactive" text="1" mdxType="Key" />} title="Inactive" mdxType="PatternExample" />
    <h3>{`Usage`}</h3>
    <UsageBlock mdxType="UsageBlock">
  <UsageBlock.Correct>
    <p>...</p>
  </UsageBlock.Correct>
  <UsageBlock.Incorrect>
    <p>...</p>
  </UsageBlock.Incorrect>
    </UsageBlock>
    <h2>{`State`}</h2>
    <PatternExample example={<Key text="1" complete={true} mdxType="Key" />} title="Complete" mdxType="PatternExample" />
    <h2>{`Layout`}</h2>
    <PatternExample example={"..."} title="..." description={"Description about layout structure"} mdxType="PatternExample" />
    <h2>{`Content`}</h2>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      